/* Checkbox Style */

.label__checkbox {
    display: none;
  }
  .label__check {
    display: inline-block;
    border-radius: 50%;
    border: 5px solid rgba(0, 0, 0, 0.1);
    background: white;
    vertical-align: middle;
    margin-right: inherit;
    width: 3em;
    height: 3em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border .3s ease;
  }
  .label__check i.icon {
    opacity: 0.2;
    font-size: calc(1rem + 1vw);
    color: transparent;
    transition: opacity .3s .1s ease;
    -webkit-text-stroke: 3px rgba(0, 0, 0, 0.5);
  }
  .label__check:hover {
    border: 5px solid rgba(0, 0, 0, 0.2);
  }
  .label__checkbox:checked + .label__text .label__check {
    -webkit-animation: check 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
            animation: check 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
  }
  .label__checkbox:checked + .label__text .label__check .icon {
    opacity: 1;
    -webkit-transform: scale(0);
            transform: scale(0);
    color: white;
    -webkit-text-stroke: 0;
    -webkit-animation: icon 0.3s cubic-bezier(1, 0.008, 0.565, 1.65) 0.1s 1 forwards;
            animation: icon 0.3s cubic-bezier(1, 0.008, 0.565, 1.65) 0.1s 1 forwards;
  }
  .center-checkbox {
  
      margin-left: 10px;
      margin-right: 10px;
  }
  
  .checkbox-container {
    display: flex;
    justify-content: center;
  }
  
  @-webkit-keyframes icon {
    from {
      opacity: 0;
      -webkit-transform: scale(0.3);
              transform: scale(0.3);
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes icon {
    from {
      opacity: 0;
      -webkit-transform: scale(0.3);
              transform: scale(0.3);
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes check {
    0% {
      width: 2em;
      height: 2em;
      border-width: 5px;
    }
    10% {
      width: 2em;
      height: 2em;
      opacity: 0.1;
      background: rgba(0, 0, 0, 0.2);
      border-width: 15px;
    }
    12% {
      width: 2em;
      height: 2em;
      opacity: 0.4;
      background: rgba(0, 0, 0, 0.1);
      border-width: 0;
    }
    50% {
      width: 3em;
      height: 3em;
      background: #00d478;
      border: 0;
      opacity: 0.6;
    }
    100% {
      width: 3em;
      height: 3em;
      background: #00d478;
      border: 0;
      opacity: 1;
    }
  }
  @keyframes check {
    0% {
      width: 2em;
      height: 2em;
      border-width: 5px;
    }
    10% {
      width: 2em;
      height: 2em;
      opacity: 0.1;
      background: rgba(0, 0, 0, 0.2);
      border-width: 15px;
    }
    12% {
      width: 2em;
      height: 2em;
      opacity: 0.4;
      background: rgba(0, 0, 0, 0.1);
      border-width: 0;
    }
    50% {
      width: 3em;
      height: 3em;
      background: #00d478;
      border: 0;
      opacity: 0.6;
    }
    100% {
      width: 3em;
      height: 3em;
      background: #00d478;
      border: 0;
      opacity: 1;
    }
  }
